import * as React from "react";
import axios from "axios";
import { Link } from "gatsby";
import { fadeInUpStyle } from "../../styles/style";

class PostsRecoment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            main_image_url: null,
            data: [],
            categories: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.fecthData();
        }
    }

    fecthData() {
        axios.get(
            'https://erp.manutenpul.it/blog/api/posts?recommend='+this.props.id,
            {
                headers: {
                    // "X-Requested-With": "XMLHttpRequest",
                    // "Access-Control-Allow-Origin": "*",
                    "Content-Type": "multipart/form-data;",
                },
                responseType: 'json',
            }).then((response) => {
                this.setState({
                    data: response.data.results
                });
            });
    }

    componentDidMount() {
        this.fecthData();
    }

    render() {
        const { data } = this.state;
        return (
            <div className="related-post mb-30">
                <h4 className="mb-40">Post correlato</h4>
                <div className="row">
                    {data.map((post) => (
                        <div className="col-md-6 col-lg-12 col-xl-6">
                        <div
                            className="single-blog mb-40 wow fadeInUp"
                            data-wow-delay=".6s"
                            style={fadeInUpStyle}
                        >
                            <div className="blog-img">
                                <Link to={"/blog/post?id=" + post.id}>
                                    <img src={post.imagen_principal} alt="post-1" />
                                </Link>
                                <span className="date-meta">{new Date(post.created_at).toDateString()} {new Date(post.created_at).toLocaleTimeString()}</span>
                            </div>
                            <div className="blog-content">
                                <h5 className="mb-15">
                                <Link to={"/blog/post?id=" + post.id} className="page-scroll">{post.titulo}</Link>
                                </h5>
                                <p>{post.introduccion}</p>
                                <Link to={"/blog/post?id=" + post.id}>Read more</Link>
                            </div>
                        </div>
                    </div>
                    ))}
                    {/* <div className="col-md-6 col-lg-12 col-xl-6">
                        <div
                            className="single-blog mb-40 wow fadeInUp"
                            data-wow-delay=".6s"
                            style={fadeInUpStyle}
                        >
                            <div className="blog-img">
                                <a href="blog-single.html">
                                    <img src="assets/img/blog/blog-3.jpg" alt="" />
                                </a>
                                <span className="date-meta">15 June, 2025</span>
                            </div>
                            <div className="blog-content">
                                <h5 className="mb-15">
                                    <a href="blog-single.html">
                                        Colorful Easter Eggs
                                    </a>
                                </h5>
                                <p>
                                    Lorem ipsum dolor sit amet, adipscing elitr, sed
                                    diam nonumy eirmod tempor ividunt dolore magna.
                                </p>
                                <a
                                    href="blog-single.html"
                                    className="read-more-btn"
                                >
                                    Read More <i className="lni lni-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default PostsRecoment;