import * as React from "react";
import axios from "axios";
import { fadeInUpStyle } from "../../styles/style";

const handleDetailImage = (image,title) =>{
    if(image != null){
      return <img src={image} alt={"img-"+title} />
    }
  };

class PostDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            main_image_url: null,
            data: [],
            categories: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.fecthData();
        }
    }

    fecthData(){
        axios.get(
            'https://erp.manutenpul.it/blog/api/post/'+this.props.id,
            {
                headers: {
                    // "X-Requested-With": "XMLHttpRequest",
                    // "Access-Control-Allow-Origin": "*",
                    "Content-Type": "multipart/form-data;",
                },
                responseType: 'json',
            }).then((response) => {
                this.setState({ 
                    data: response.data.detalles,
                    title: response.data.titulo,
                    main_image_url: response.data.imagen_principal,
                    categories: response.data.categoria
                });
        });
    }

    componentDidMount() {
        this.fecthData();
    }

    render() {
        const { data,main_image_url } = this.state;
        return (
            <div className="single-blog blog-style-2 mb-60 wow fadeInUp" data-wow-delay=".2s" style={fadeInUpStyle}>
                <div className="blog-img blog-img-2">
                    <img src={main_image_url} alt="main-img" />
                </div>
                <div className="blog-content">
                    <h1>Start a Business Guide</h1>
                    {data.map((detail) => (
                        <div>
                            <h4>{detail.subtitulo}</h4>
                            <p className="mb-40">{detail.texto}</p>
                            {handleDetailImage(detail.texto_imagen,detail.subtitulo)}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default PostDetail;