import * as React from "react";
import { pageStyles } from "../../styles/style";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import { Helmet } from "react-helmet";
import FloatButton from "../../components/FloatButton";
import "../../styles/main.css";
import CategoriesList from "../../components/Blog/Categories.jsx";
import PostDetail from "../../components/Blog/PostDetail.jsx";
import BlogRecentList from "../../components/Blog/BlogRecent.jsx";
import PostsRecoment from "../../components/Blog/PostsRecoment";

class BlogPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null
    };
  }

  componentDidMount(){
    this.setId();
  }

  setId(){
      // code here
      let search = window.location.search;
      let params = new URLSearchParams(search);
      this.setState({ 
        id: params.get("id"),
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // let get_pararms = () => new URLSearchParams(search), [search];
      this.setId();
    }
  }

  render() {
    const { id } = this.state;
    return (
      <main style={pageStyles}>
        <FloatButton />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Blog</title>
          <meta
            name="keywords"
            content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi"
          />
        </Helmet>
        <Navbar />
        <Banner pageName="Blog" />
        <section className="blog-section pt-130">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-7">
                <div className="left-side-wrapper mb-60">
                  <PostDetail id={id}></PostDetail>
                  <PostsRecoment id={id}></PostsRecoment>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5">
                <div className="sidebar-wrapper mb-60">
                  <BlogRecentList post_id={id} />
                  <CategoriesList></CategoriesList>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </main>
    );
  }
}
export default BlogPageComponent;
