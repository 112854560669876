import * as React from "react";
import axios from "axios";
import {Link} from "gatsby";

class CategoriesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        axios.get(
            'https://erp.manutenpul.it/blog/api/categories/',
            {
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                responseType: 'json',
            }).then((response) => {
                this.setState({ data: response.data })
            });
    }

    render(){
        const { data } = this.state;
        return (
            <div class="sidebar-box catagories-box mb-30">
                <h4>Categorie</h4>
                <ul>
                    {data.map((categorie) => ( 
                    <li>
                        <Link to={"/blog?category=" + categorie.id} className="page-scroll">{categorie.nombre}</Link>
                    </li>
                    ))}
                </ul>
            </div>
        )
    }
}
// export default () => (
//     <CategoriesList />
// );
// const main = () => (
//     <CategoriesList />
// );
// export default main;
export default CategoriesList;