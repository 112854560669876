import * as React from "react";
import axios from "axios";
import { Link } from "gatsby";


class BlogRecentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.post_id !== prevProps.post_id) {
            this.fecthData();

        }
    }

    fecthData(){
        let url = 'https://erp.manutenpul.it/blog/api/posts?no_id=' + this.props.post_id;
        axios.get(
            url,
            {
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                responseType: 'json',
            }).then((response) => {
                this.setState({ data: response.data.results })
        });
    }

    componentDidMount() {
        this.fecthData();
    }

    render() {
        const { data } = this.state;
        return (
            <div className="sidebar-box recent-blog-box mb-30">
                <h4>Blog recente</h4>
                <div className="recent-blog-items">
                    {data.map((post) => (
                        <div className="recent-blog mb-30">
                            <div className="recent-blog-img">
                                <Link to={"/blog/post?id=" + post.id} className="page-scroll">
                                    <img src={post.imagen_principal} alt="example1" />
                                </Link>
                            </div>
                            <div className="recent-blog-content">
                                <h5><Link to={"/blog/post?id=" + post.id} className="page-scroll">{post.titulo}</Link></h5>
                                <span className="date">{new Date(post.created_at).toDateString()} {new Date(post.created_at).toLocaleTimeString()}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default BlogRecentList;